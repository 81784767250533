var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{staticClass:"pass-dialog",attrs:{"persistent":"","max-width":"360"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"fs-18 font-weight-medium f-inter textPrimary--text justify-space-between",staticStyle:{"letter-spacing":"-0.3px"}},[_vm._v(" Change Password "),_c('span',{staticClass:"material-icons custom-close-icon mt-3",on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" close ")])]),_c('v-divider',{staticStyle:{"border":"0.3px solid #e8ebf6"}}),_c('v-form',{ref:"changePassword",staticClass:"mt-5 pl-5 pr-5"},_vm._l((_vm.changePasswordFields),function(input,index){return _c('v-text-field',{key:index,staticClass:"text-no-wrap rounded-md custom",attrs:{"filled":"","label":input.label,"placeholder":input.placeholder,"type":input.type,"background-color":"inputBackground","flat":"","autocomplete":"off","rules":input.name == 'newPassword'
            ? _vm.oldPasswordRule
            : input.name == 'confirmPassword'
            ? _vm.confirmPasswordRules
            : _vm.requiredField,"append-icon":input.name == 'password' ||
          input.name == 'newPassword' ||
          input.name == 'confirmPassword'
            ? input.showIcon
            : ''},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.updatePassword()},"click:append":() =>
            input.type == 'password'
              ? ((input.type = 'text'), (input.showIcon = 'visibility'))
              : ((input.type = 'password'),
                (input.showIcon = 'visibility_off'))},model:{value:(input.model),callback:function ($$v) {_vm.$set(input, "model", $$v)},expression:"input.model"}})}),1),_c('v-card-actions',{staticClass:"pb-5 justify-center"},[_c('v-btn',{staticClass:"primary--text buttonBg fs-13 font-weight-medium f-avenir pass-btn rounded-lg",staticStyle:{"text-transform":"none !important","letter-spacing":"-0.3px"},attrs:{"loading":_vm.loader,"elevation":"0","block":""},on:{"click":function($event){return _vm.updatePassword()}}},[_vm._v(" Update Password ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }