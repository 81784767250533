<template>
  <div>
    <!-- CHANGE PASSWORD MODAL -->
    <ChangePassword ref="changePassword" />

    <!-- CHANGE EMAIL MODAL -->
    <ChangeEmail ref="changeEmail" />

    <h3 :class="$vuetify.breakpoint.lg ? 'ml-15 mt-5' : 'ml-6 mt-2'">
      Profile Settings
    </h3>
    <v-card style="width: 80%; margin: auto; margin-top: 30px" elevation="0">
      <v-row
        v-for="(info, index) in personalInfo"
        :key="index"
        align="baseline"
        class="mt-6"
        v-show="info.role.includes(user.role)"
      >
        <!-- <v-col cols="1"></v-col> -->
        <v-col
          :cols="$vuetify.breakpoint.lg ? 3 : 12"
          :class="$vuetify.breakpoint.lg ? '' : 'py-0'"
        >
          <p class="fw-600 fs-14 f-inter mb-0">{{ info.name }}</p>
        </v-col>
        <v-col class="pt-0 pb-0" :cols="$vuetify.breakpoint.lg ? 5 : 12">
          <v-text-field
            v-if="info.type == 'text'"
            outlined
            dense
            :placeholder="info.placeholder"
            v-model="info.value"
            :disabled="info.disabled"
            class="fs-12 f-inter fw-400"
            :rules="info.rules"
            hide-details
          />
          <v-select
            v-if="info.type == 'dropdown'"
            class="fs-12 fw-400 f-inter"
            dense
            outlined
            v-model="countryOption.selected"
            :items="countryOption.options"
            item-text="label"
            item-value="value"
            hide-details
          />
          <v-select
            v-if="info.type == 'designation'"
            class="fs-12 fw-400 f-inter"
            dense
            outlined
            v-model="companyPosition.selected"
            :items="companyPosition.options"
            item-text="label"
            item-value="value"
            hide-details
          />
          <v-combobox
            v-if="info.type == 'currency'"
            class="fs-12 fw-400 f-inter"
            outlined
            dense
            v-model="currencyOption.selected"
            :items="currencyOption.options"
            item-text="value"
            item-value="value"
            :rules="info.rules"
            hide-details
          ></v-combobox>
        </v-col>
        <v-col
          v-if="info.changeEmail"
          :cols="$vuetify.breakpoint.lg ? 3 : 12"
          :style="
            $vuetify.breakpoint.lg ? '' : 'text-align: end; padding: 0 12px;'
          "
        >
          <v-btn
            text
            class="lightPurple--text change-credentials fw-600 fs-11 f-inter font-italic text-decoration-underline text-none"
            dense
            @click="updateEmail()"
            >Change Email</v-btn
          >
        </v-col>
        <v-col
          v-if="info.changePassword"
          :cols="$vuetify.breakpoint.lg ? 3 : 12"
          :style="
            $vuetify.breakpoint.lg ? '' : 'text-align: end; padding: 0 12px;'
          "
        >
          <v-btn
            text
            class="lightPurple--text change-credentials fw-600 fs-11 f-inter font-italic text-decoration-underline text-none"
            dense
            @click="updatePassword()"
            >Change Password</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="7"></v-col>
        <v-col cols="5" class="px-0">
          <div
            :class="
              $vuetify.breakpoint.lg
                ? 'd-flex justify-start'
                : 'd-flex justify-end'
            "
          >
            <v-btn
              class="fs-12 fw-600 lightPurple--text rounded-lg px-6 elevation-0"
              :loading="loader"
              @click="updateUserProfile()"
            >
              Save</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import CountryCode from "@/constants/sign-up/countryCode";
import CurrencyList from "@/constants/sign-up/currencyList";
// import apiService from "@/services/Api.js";
import { mapActions, mapGetters } from "vuex";
// import UrlServices from "@/services/Url";
import ChangeEmail from "../modals/ChangeEmail.vue";
import ChangePassword from "../modals/ChangePassword.vue";
// import EditPersonalInfo from "../modals/EditPersonalInfo.vue";
// import EditWorkspace from "../modals/EditWorkspace.vue";
import rules from "../../../constants/validation-rules.js";

export default {
  components: {
    ChangeEmail,
    ChangePassword,
    // FormInput,
    // EditPersonalInfo,
    // EditWorkspace,
  },
  data() {
    return {
      loader: false,
      requiredRule: [rules.required],
      personalInfo: [
        {
          name: "Name",
          value: "",
          disabled: false,
          placeholder: "Full name",
          type: "text",
          rules: [rules.required],
          role: ["employee", "client"],
        },
        {
          name: "Company Name",
          value: "",
          disabled: false,
          placeholder: "Company name",
          type: "text",
          role: ["client"],
        },
        {
          name: "Designation",
          value: "",
          disabled: false,
          placeholder: "Designation",
          type: "designation",
          role: ["client"],
        },
        {
          name: "Country",
          value: "",
          disabled: false,
          placeholder: "Country",
          type: "dropdown",
          role: ["client"],
          // items: CountryCode,
          // rules: [rules.select_field_required],
        },
        {
          name: "Currency",
          value: "",
          disabled: false,
          placeholder: "Currency",
          type: "currency",
          role: ["client"],
          // items: CurrencyList,
          // rules: [rules.select_field_required],
        },
        {
          name: "Email",
          value: "",
          changeEmail: true,
          disabled: true,
          placeholder: "Email",
          type: "text",
          role: ["employee", "client"],
        },
        {
          name: "Password",
          value: "",
          changePassword: true,
          disabled: true,
          placeholder: "********",
          type: "text",
          role: ["employee", "client"],
        },
      ],

      currencyOption: {
        selected: [],
        options: CurrencyList,
      },
      countryOption: {
        selected: "",
        options: [],
      },
      companyPosition: {
        selected: null,
        selectedModelForOther: "",
        options: [
          "CEO (Chief Executive Officer)",
          "Director",
          "COO (Chief Operating Officer)",
          "CFO (Chief Financial Officer)",
          "Other",
        ],
        // options: [
        //   { label: "CEO (Chief Executive Officer)", value: "CEO" },
        //   { label: "Director", value: "director" },
        //   { label: "COO (Chief Operating Officer)", value: "COO" },
        //   { label: "CFO (Chief Financial Officer)", value: "CFO" },
        //   { label: "Other", value: "other" },
        // ],
      },

      // ---------------
      countryName: "",
      // auditTicketSwitch: false, //org
      // invoiceRewriteSwitch: false, //org
      employeeInfo: [
        {
          name: "Full Name",
          value: "",
        },
        {
          name: "Email",
          value: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      currentWorkspace: "auth/getCurrentWorkspace",
      // token: "auth/getAuthToken",
    }),
  },

  mounted() {
    CountryCode.find((country) => {
      this.countryOption.options.push(country.label);
    });

    if (localStorage.getItem("token"))
      this.token = localStorage.getItem("token");
    this.user.role === "client" ? this.updateInfo() : this.updateEmployeeInfo();
  },
  methods: {
    /**
     * Method to set company position
     */
    // setCompanyPosition(companyPosition) {
    //   const found = this.companyPosition.options.find(
    //     (el) => el.value === companyPosition
    //   );
    //   return found ? found.label : companyPosition;
    // },
    updateEmployeeInfo() {
      this.personalInfo.forEach((info) => {
        info.name == "Name"
          ? (info.value = this.user.name)
          : info.name == "Email"
          ? (info.value = this.user.email)
          : "";
      });
    },
    // Method to fetch personal info of client
    updateInfo() {
      let name = this.user.name;
      let countryName = CountryCode.find((country) => {
        if (country.value === this.user.country) {
          return country.label;
        }
      });
      countryName =
        typeof countryName === "object" ? countryName.label : countryName;
      let currency = this.user.currency;
      // currency = currency.replaceAll(",", ", ");
      // let companyPosition = this.user.companyPosition
      //   ? this.user.companyPosition
      //   : "";
      this.personalInfo.forEach((info) => {
        info.name == "Designation"
          ? (this.companyPosition.selected = this.user.companyPosition)
          : info.name == "Company Name"
          ? (info.value = this.user.companyName)
          : info.name == "Country"
          ? (this.countryOption.selected = countryName)
          : info.name == "Currency"
          ? (this.currencyOption.selected = currency)
          : info.name == "Name"
          ? (info.value = name)
          : info.name == "Email"
          ? (info.value = this.user.email)
          : "";
      });
    },

    // Method to open dialog to change an email
    updateEmail() {
      this.$refs.changeEmail.openDialog();
    },
    updatePassword() {
      this.$refs.changePassword.openDialog();
    },

    // Method to update user profile
    updateUserProfile() {
      let updatedUserObj = {};
      if (this.user.role == "client") {
        let country = CountryCode.find((country) => {
          if (this.countryOption.selected === country.label)
            return country.value;
        });
        updatedUserObj = {
          name: this.personalInfo[0].value,
          companyName: this.personalInfo[1].value,
          companyPosition: this.companyPosition.selected,
          country: country.value,
          currency: this.currencyOption.selected.value
            ? this.currencyOption.selected.value
            : this.user.currency,
        };
      } else {
        updatedUserObj = {
          name: this.personalInfo[0].value,
        };
      }
      this.loader = true;
      this.updateUser(updatedUserObj)
        .then((res) => {
          console.log(res, "data");
          this.loader = false;
          this.$showNotificationBar("success", res.message);
        })
        .catch((err) => {
          console.log(err.response.data, "Errr");
          this.loader = false;
          this.$showNotificationBar("error", err.response.data.message);
        });
    },
    // -------------------
    ...mapActions({
      updateUser: "auth/updateUser",
    }),

    /**
     * Method to enable/disable ticket approval
     */
    // enableDisableAudit() {
    //   apiService
    //     .PostRequest(
    //       UrlServices.enableDisableAudit + "/" + this.currentWorkspace._id,
    //       {
    //         auditTicket: this.auditTicketSwitch,
    //       }
    //     )
    //     .then((res) => {
    //       const enabled = res.data.msg.auditTicket;
    //       this.$store.commit("auth/setUserSpecificProperty", {
    //         key: "auditTicket",
    //         value: enabled,
    //       });
    //       this.$showNotificationBar(
    //         "success",
    //         `Audit of ticket request has been ${
    //           enabled ? "enabled" : "disabled"
    //         } successfully`
    //       );
    //     })
    //     .catch((e) => {
    //       console.log("err>>", e);
    //     });
    // },

    /**
     * Method to fetch user details in modal
     */
    // discard
    // openEditProfileDialog() {
    //   this.$refs.editPersonalInfo.openEditProfileDialog();
    // },
  },
};
</script>

<style scoped>
.change-credentials {
  min-width: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  text-underline-offset: 3px;
}
/* ----------- */
/* .edit-button.v-btn:not(.v-btn--round).v-size--default {
  height: 32px !important;
  border-radius: 6px !important;
} */
/* .upload-icon {
  width: 25px;
  height: 25px;
  background-color: rgba(252, 252, 252, 1);
  position: relative;
  left: 113px;
  top: 68px;
  z-index: 1;
  border-radius: px;
} */
/* .custom-height {
  min-height: 43vh;
} */
/* .custom-height-second-block {
  min-height: 50vh;
} */
/* .file-upload {
  position: relative;
  top: 6px;
  left: 3px;
} */
/* .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
} */

/* .profile-card {
  background-color: var(--v-inputBackground-base) !important;
  border-radius: 5px;
  min-height: 241px;
} */
/* .edit-button {
  width: 64.84px;
  border: 1px solid var(--v-primary-base) !important;
  border-radius: 6px;
  color: var(--v-primary-base) !important;
} */

/* :deep .v-btn--is-elevated {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 2px 2px 0px rgb(0 0 0 / 0%),
    0px 1px 5px 0px rgb(0 0 0 / 0%) !important;
} */

/* :deep .v-input__slot {
  margin-bottom: 0 !important;
}
.default-selection-dropdown .v-select__slot label {
  z-index: 1;
}
.default-selection-dropdown .v-select__selections {
  padding-top: 5px !important;
}
.default-selection-dropdown .v-text-field__slot {
  margin-top: 10px;
} */

/* when edit personal info then disabled btn css  */
/* .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #f5f2fd !important;
  color: var(--v-primary-base) !important;
} */

:deep
  .v-input--is-label-active
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-input--switch__track {
  height: 20px !important;
  width: 44px !important;
  background: #6634e9 !important;
  opacity: 1 !important;
}
:deep .v-input--selection-controls__input .v-input--switch__track {
  height: 20px !important;
  width: 44px !important;
}
:deep .v-input--selection-controls__input .v-input--switch__thumb {
  height: 16px !important;
  top: calc(50% - 12px) !important;
  width: 15px !important;
  background: white;
}
/* :deep .v-btn:before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0px !important;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: -8px !important;
  top: -9px !important;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
} */
</style>
